import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.section`
  padding: 3rem 2rem;
  background-color: #1a1a1a;
  color: #e0e0e0;
  text-align: center;
`;

const AboutTitle = styled.h2`
  font-size: 2rem;
  color: #00bcd4;
  margin-bottom: 1rem;
`;

const AboutText = styled.p`
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
`;

const AboutMe = () => (
  <AboutContainer>
    <AboutTitle>About Me</AboutTitle>
    <AboutText>
      Sou um desenvolvedor backend especializado em C# e inteligência artificial, com sólida experiência em criar arquiteturas de software escaláveis e de alta performance. Com uma abordagem empreendedora e paixão por inovação, atuo para alinhar tecnologia e negócios, desenvolvendo soluções que atendem aos desafios do mercado moderno. Além disso, sou co-fundador de empresas e já ocupei cargos de liderança como CTO, onde direcionei equipes de tecnologia e participei ativamente na criação e expansão de produtos digitais inovadores.
    </AboutText>
  </AboutContainer>
);

export default AboutMe;

import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to bottom, #121212, #1a1a1a, #0d0d0d);
    color: #e0e0e0;
    overflow-x: hidden;
  }
  h2 {
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #00bcd4;
    text-align: center;
  }
  p {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.8;
  }
`;

export default GlobalStyle;

import React from 'react';
import styled from 'styled-components';
import { FaCode, FaProjectDiagram, FaBrain, FaRocket } from 'react-icons/fa';

const SkillsContainer = styled.section`
  padding: 3rem 2rem;
  background-color: #1a1a1a;
  color: #e0e0e0;
  text-align: center;
`;

const SkillsTitle = styled.h2`
  font-size: 2rem;
  color: #00bcd4;
  margin-bottom: 1rem;
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
`;

const SkillCard = styled.div`
  background-color: #1f1f1f;
  padding: 1.5rem;
  border-radius: 8px;
  width: 180px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SkillIcon = styled.div`
  font-size: 2rem;
  color: #00bcd4;
  margin-bottom: 0.5rem;
`;

const SkillTitle = styled.h3`
  font-size: 1.1rem;
  color: #00bcd4;
  margin-bottom: 0.5rem;
`;

const SkillDescription = styled.p`
  font-size: 0.9rem;
  color: #e0e0e0;
`;

const Skills = () => (
  <SkillsContainer>
    <SkillsTitle>Skills</SkillsTitle>
    <SkillsList>
      <SkillCard>
        <SkillIcon><FaCode /></SkillIcon>
        <SkillTitle>Desenvolvimento Backend</SkillTitle>
        <SkillDescription>Especialista em C# e .NET, com ampla experiência em desenvolver sistemas robustos e escaláveis.</SkillDescription>
      </SkillCard>
      <SkillCard>
        <SkillIcon><FaProjectDiagram /></SkillIcon>
        <SkillTitle>Arquitetura de Software</SkillTitle>
        <SkillDescription>Conhecimento em arquiteturas escaláveis e de alta performance, como microserviços, DDD e REST.</SkillDescription>
      </SkillCard>
      <SkillCard>
        <SkillIcon><FaBrain /></SkillIcon>
        <SkillTitle>Inteligência Artificial</SkillTitle>
        <SkillDescription>Desenvolvimento de modelos de IA aplicados para soluções práticas e necessidades do mercado.</SkillDescription>
      </SkillCard>
      <SkillCard>
        <SkillIcon><FaRocket /></SkillIcon>
        <SkillTitle>Empreendedorismo</SkillTitle>
        <SkillDescription>Experiência em startups e liderança tecnológica, transformando ideias em negócios sustentáveis.</SkillDescription>
      </SkillCard>
    </SkillsList>
  </SkillsContainer>
);

export default Skills;

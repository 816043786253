import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  text-align: center;
  padding: 5rem 2rem;
  background: linear-gradient(135deg, #002f4b, #005f73);
  color: #e0e0e0;
`;

const ProfileImage = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #333;
  margin: 0 auto 1.5rem;
  background-image: url('/img/me.webp');
  background-size: cover;
  background-position: center;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
`;

const Subtitle = styled.h2`
  font-size: 1.4rem;
  color: #00bcd4;
  margin-top: 0.5rem;
`;

const ContactLink = styled.a`
  display: inline-block;
  background: #00bcd4;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s;
  margin-top: 1rem;

  &:hover {
    background: #008ba3;
  }
`;

const Header = () => (
  <HeaderContainer>
    <ProfileImage />
    <Title>Carlos Balsalobre</Title>
    <Subtitle>Backend Developer | AI Specialist | Entrepreneur</Subtitle>
    <ContactLink href="https://br.linkedin.com/in/carlos-balsalobre" target="_blank">
      linkedin.com/in/carlos-balsalobre
    </ContactLink>
  </HeaderContainer>
);

export default Header;

import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.section`
  padding: 3rem 2rem;
  text-align: center;
  background-color: #0d0d0d;
`;

const ContactTitle = styled.h2`
  font-size: 2rem;
  color: #00bcd4;
  margin-bottom: 1rem;
`;

const ContactLink = styled.a`
  display: inline-block;
  background: #00bcd4;
  color: #fff;
  padding: 0.75rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background 0.3s;
  margin-top: 1rem;

  &:hover {
    background: #008ba3;
  }
`;

const Contact = () => (
  <ContactContainer>
    <ContactTitle>Contact</ContactTitle>
    <h4>Vamos nos conectar!</h4>
    <p>Acesse meu LinkedIn para saber mais sobre meu trabalho e trajetória:</p>
    <ContactLink href="https://br.linkedin.com/in/carlos-balsalobre" target="_blank">
      linkedin.com/in/carlos-balsalobre
    </ContactLink>
  </ContactContainer>
);

export default Contact;

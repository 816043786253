import React from 'react';
import styled from 'styled-components';

const ExperienceContainer = styled.section`
  padding: 3rem 2rem;
  background-color: #121212;
  color: #e0e0e0;
`;

const ExperienceTitle = styled.h2`
  font-size: 2rem;
  color: #00bcd4;
  text-align: center;
  margin-bottom: 1rem;
`;

const ExperienceList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
`;

const ExperienceCard = styled.div`
  background-color: #1f1f1f;
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
`;

const ExperienceCardTitle = styled.h3`
  font-size: 1.25rem;
  color: #00bcd4;
  margin-bottom: 0.5rem;
`;

const ExperienceText = styled.p`
  font-size: 1rem;
`;

const Experience = () => (
  <ExperienceContainer>
    <ExperienceTitle>Experience</ExperienceTitle>
    <ExperienceList>
      <ExperienceCard>
        <ExperienceCardTitle>uCondo – Tech Lead .Net</ExperienceCardTitle>
        <ExperienceText>
          Atual posição como líder técnico, responsável pelo desenvolvimento de aplicações backend de alta escala, aplicando as melhores práticas de engenharia de software e liderando projetos que envolvem tecnologias de ponta.
        </ExperienceText>
      </ExperienceCard>
      <ExperienceCard>
        <ExperienceCardTitle>TENNTS – CTO</ExperienceCardTitle>
        <ExperienceText>
          Atuei como CTO em uma startup de gestão de aluguel, liderando a equipe de TI, definindo arquiteturas de software e escalando o produto para alcançar mais usuários. Com foco em garantir alinhamento entre tecnologia e negócios, trouxe inovação ao setor de gestão de propriedades.
        </ExperienceText>
      </ExperienceCard>
      <ExperienceCard>
        <ExperienceCardTitle>Neomode – Co-Fundador e CTO</ExperienceCardTitle>
        <ExperienceText>
          Como co-fundador e CTO, desempenhei um papel essencial na criação de uma startup de tecnologia, focada em soluções escaláveis para o mercado varejista. Liderei equipes e dirigi iniciativas tecnológicas para transformar ideias em produtos tangíveis.
        </ExperienceText>
      </ExperienceCard>
    </ExperienceList>
  </ExperienceContainer>
);

export default Experience;
